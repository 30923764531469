export const monthNames = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"];

export const monthNamesShort = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];

export const dayNames = ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"];

export function pad(value) {
    if(value < 10) {
        return '0' + value;
    } else {
        return value;
    }
}     

export function replaceWords(p) {
    switch(p) {
        case 'race':
            return 'løb';
        case 'news':
            return 'nyhed';
        case 'event':
            return 'event';
        case 'clockwise':
            return 'med uret';
        case 'counterclockwise':
            return 'mod uret';
        default:
            return p
    }
}

export const regex = /(<([^>]+)>)/ig;
